import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNews } from './newsAPI';

const initialState = {
    value: [],
    status: 'idle'
}

export const fetchAsync = createAsyncThunk(
    'news/fetchNews',
    async ({amount,language}) => {
        const response = await fetchNews(amount,language);
        return response;
    }
)

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder
        .addCase(fetchAsync.pending, (state)=>{
            state.status='loading';
        })
        .addCase(fetchAsync.fulfilled, (state,action)=>{
        
                state.status = 'idle';
                state.value = action.payload.news;//[...state.value,...action.payload.news];
        })
    }
});

export const selectNews = (state) => state.news.value;

export default newsSlice.reducer