// import { t } from 'i18next';
import {React} from 'react';
import Navbar from '../components/Navbar';
import styles from './About.module.css';


const Support = () => {





  
  return (
    <>
      <Navbar />
      <div className="container">
        <div className='bgtop'></div>
        <div className='maincontext'>
          <div className='context_middle'>

          <div className={styles.text}>
          
          <p>We appreciate your support</p>

          <p>We are dedicated to providing you with the latest news, translated from Ukrainian sources into multiple languages, completely free of charge. Our goal is to keep you informed, no matter where you are in the world.</p>

          <p>Maintaining and improving our service requires resources, and your support can make a real difference. By contributing a small tip, you help us continue to deliver quality news content, enhance our services, and keep the website free for all users.</p>

          <p>Your support means the world to us. It enables us to expand our reach, cover more topics, and bring you the news you need. Every little bit helps us in our mission to keep you informed and connected to the world.</p>

          <p>Thank you for being a part of our community and for considering supporting us. Together, we can ensure that reliable news remains accessible to everyone.</p>

          <p>Support us via <a href="https://www.buymeacoffee.com/ua.media"><b>Buy me a coffee</b></a> or <a href='https://cwallet.com/t/OR6A53QY'><b>Crypto</b></a></p>

          </div>
          <div className="flex flex-row ...">
            <div><img src='bmc_qr.png' alt="" width='160px' style={{padding: 10}}></img></div>
            <div><img src='QRCode.jpeg' alt="" width='160px'style={{padding: 10}}></img></div>
           
          </div>
        
          </div>
        </div>  
      </div>
    </>
  );
};
  
export default Support;