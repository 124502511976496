
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SidebarComponent = () => {
   const [sidebarNews, setSidebarNews] = useState([]);

   useEffect(() => {
    
      const fetchSidebarNews = async () => {
        try {
          const url = process.env.REACT_APP_SERVER_API+`/api/sidebarnews`;
          const response = await axios.get(url);
          setSidebarNews(response.data);
        } catch (error) {
          
        }
      };

      fetchSidebarNews();
    
  }, []);

  return (
    <div>
      {sidebarNews.map((article, index) => (
        <div key={index}>
         
         <Link to={`/read/${article.id}`}
         key={article.id}
         className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
         >
          <img src={article.image} alt={article.header}/>
         </Link>
          <p>{article.header}</p>
          
         </div>
       ))}
        
     </div>
 
 
  );
};

export default SidebarComponent;