import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (searchTerm && searchTerm.length>2) {
      const fetchSuggestions = async () => {
        try {
          const url = process.env.REACT_APP_SERVER_API+`/api/search/${searchTerm}`;
          const response = await axios.get(url);
          setSuggestions(response.data);
          
        } catch (error) {
          
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  return (
    <div className="relative w-1/3">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        />
        {suggestions.length > 0 && (
     <ul className="suggestions-list">
       {suggestions.map((suggestion, index) => (
         <li key={index}>
         <Link to={`/read/${suggestion.id}`}
         key={suggestion.id}
         className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
         >{suggestion.header}
         </Link>
         </li>
       ))}
     </ul>
   )}
     </div>
 
 
  );
};

export default SearchComponent;