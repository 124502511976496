import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchArticle } from './articleAPI';

const initialState = {
    value: [],
    status: 'idle'
}

export const fetchAsync = createAsyncThunk(
    'article/fetchArticle',
    async ({id,language}) => {
        const response = await fetchArticle(id,language);
        return response;
    }
)

export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder
        .addCase(fetchAsync.pending, (state)=>{
            state.status='loading';
        })
        .addCase(fetchAsync.fulfilled, (state,action)=>{
                state.status = 'idle';
                state.value = action.payload.article;
        })
    }
});

export const selectArticle = (state) => state.article.value;

export default articleSlice.reducer