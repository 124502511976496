// import { t } from 'i18next';
import {React,useState} from 'react';
import Navbar from '../components/Navbar';
import styles from './About.module.css';
import { useTranslation } from "react-i18next";
import axios from 'axios';

const About = () => {

  const [contact,setContact] = useState("");
  const [text,setText] = useState("");
  const [msgsent,setMsgSent] = useState(false);
  const [error,setError]=useState('');
  const { t } = useTranslation();

  const SendMessage=()=>{
    
    if(contact!=="" && text!==""){
    axios.post(process.env.REACT_APP_SERVER_API + "/api/sendmsg",{
      'contact':contact,
      'text':text
      }
  ).then(response => {
      if (response.data.status===200) {
        setMsgSent(true);
      }else
      setError(response.data.data)
  });

  }else{
    }
  }
  return (
    <>
      <Navbar />
      <div className="container">
        <div className='bgtop'></div>
        <div className='maincontext'>
          <div className='context_middle'>

          <div className={styles.text}>
          <p>{t('FirstMessage')}.</p> 
          <p>{t('SecondMessage')}.</p>
          <p>{t('ThirdMessage')}.</p>
          </div>
          { !msgsent
           ? <div>
            <div className="mb-6">
            <p>{error}</p>
             <label htmlFor="contactUs" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Contact us')}</label>
          
           </div>
           <div className="mb-6">
             <label htmlFor="headline" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Your email/phone/socials')}</label>
             <input type="email" id="headline" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Some contact info" 
             onChange={text => setContact(text.target.value)}
             maxLength="100"
             required/>
           </div>
           <div className="mb-6">
             
         <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Few more words')}</label>
         <textarea id="message" rows="10" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
         onChange={text => setText(text.target.value)}  maxLength="1000"
         placeholder="Tell us more..."></textarea>
 
         </div>
          
           <button onClick={()=>SendMessage()} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{t('Send Message')}</button>
 </div>
 
           
           
          //  <div className={styles.contact}>
          //   <p>{error}</p>
          //   <p>{t('Contact us')}</p>
          //   <div className={styles.info}><label>{t('Your email/phone/socials')}</label><br/><input id="cont" onChange={text => setContact(text.target.value)}  type="text" maxLength="100"/></div>
          //   <div className={styles.msgtext}><label>{t('Message')}</label><br/><textarea id="msg" cols="50" rows="7" onChange={text => setText(text.target.value)}  maxLength="500"></textarea></div>
          //   <div><button onClick={()=>{SendMessage();}}>{t('Send Message')}</button></div>
          // </div>
          :
          <div className={styles.contact}>
          <p>{t('Thank you for your message')}</p>
          </div>
          }



          </div>
        </div>  
      </div>
    </>
  );
};
  
export default About;