export async function fetchNews(amount=30,language=1) {
    // if(language==="uk"){
    //     language=1;
    // }else if(language==='en'){
    //     language=2;
    // }else
    // {
    //     language=2;
    // }        
    return await fetch(process.env.REACT_APP_SERVER_API+"/api/news?count="+amount+"&lang="+language)
    .then(response => response.json())
    .catch(err => console.error(err));
}