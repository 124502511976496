import Navbar from "./Navbar";
// import styles from "./AddNews.module.css";
// import reCAPTCHA from "react-google-recaptcha"
import { React, useState } from "react";
import { t } from "i18next";
// import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function AddNews(){


  // const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

  const [header,setHeader] = useState("");
  const [full,setFull] = useState("");
  const [attachment, setAttachment] = useState([]);

  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());

  const addNewsArticle = ()=>{
    const data = convertToRaw(editorState.getCurrentContent());
    console.log(data);
    console.log(header);
    console.log(full);
    console.log(attachment);

    setHeader("")
    setFull("")
    setAttachment("")
    

  }


  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const data = convertToRaw(editorState.getCurrentContent());
    };
  
    return (
        <div>
          <Navbar />
          <div className="container">
            <div className='bgtop'></div>
            <div className='maincontext'>
              <div className='context_middle'>
            


  <div className="mb-6">
    <label htmlFor="headline" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('News Headline')}</label>
    <input type="text" id="headline" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="News Headline" 
    onChange={text => setHeader(text.target.value)}
    maxLength="100"
    required/>
  </div>
  <div className="mb-6">
    
<label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Few more words')}</label>
<textarea id="message" rows="10" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
onChange={text => setFull(text.target.value)}  maxLength="1000"
placeholder="Tell more about the news..."></textarea>

</div>
  <div className=" mb-6">

  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="news_image">Upload image</label>
<input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="news_image_help" 
onChange={()=>setAttachment(document.getElementById("news_image").files[0])}
id="news_image" type="file"/>
<div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="news_image_help">An image will help the news understand it</div>

  </div>
  <button type="submit" onClick={()=>addNewsArticle()} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>

              </div>
            </div>  
          </div>
        </div>
      );
}