import React,{Suspense} from 'react';
import "../css/navbar.css"
import { useState } from "react"
import { useTranslation } from "react-i18next";
// import logo from '../all-lights-on.png';
import SearchComponent from './SearchComponent';


export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    //create a function to handle the click event on the hamburger menu
    const handleNavCollapse = () => {
        setIsNavExpanded(!isNavExpanded)
    }
    //const [isOpen, setOpen] = useState(false);

//   const handleDropDown = () => {
//     setOpen(!isOpen);
//   };
    

    return (
    <Suspense fallback="loading">

<div>
    {/* <div className='topnavigation2'>
        <div className='navbarmain'>
            <div className='navbar1'>
                
                <a href='/' className='brand-name-text'>UaMedia</a>
            </div>
            <div className='navbar2'>
                <div className="fresnel-container fresnel-lessThan-sm"></div>
                <div className='navbar21'>
                    <div className='navbar211'>
                        <div className='fresnel-container fresnel-greaterThanOrEqual-sm'>
                            <div className='navbar2111'>
                                <div className='navbar2111topsch'>
                                    <SearchComponent />
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div
            className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
        >
            <ul className='navbarlinks'>
                    
                    <li className='navbarullinksli'><a href="/" className='navbarlia'>{t('News Feed')}</a></li>
                    
                    <li className='navbarullinksli'><a href="/about" className='navbarlia'>{t('About')}</a></li>
                    <li className='navbarullinksli'><a href="/support" className='navbarlia'>{t('Support')}</a></li>
                    <li className='navbarullinksli'>


<button id="dropdownDefaultButton" onClick={()=>handleDropDown()} data-dropdown-toggle="dropdown" className="text-black hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Language <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
  </svg>
</button>

<div id="dropdown" className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ${isOpen ? "block" : "hidden"}`}>



    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
      <li>
      <button onClick={()=>changeLanguage('en')} className='btn-lng'>
                        <img src="/en.png" alt="English" />
                    </button>
      </li>
      <li>
      <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
      </li>
      <li>
      <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
      </li>
      <li>
      <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
      </li>
    </ul>
</div>  





                    <button onClick={()=>changeLanguage('en')} className='btn-lng'>
                        <img src="/en.png" alt="English" />
                    </button>
                    <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
                    <button onClick={()=>changeLanguage('ge')} className='btn-lng'>
                        <img src="/ge.png" alt="German" />
                    </button>
                    <button onClick={()=>changeLanguage('fr')} className='btn-lng'>
                        <img src="/fr.png" alt="French" />
                    </button>
                    <button onClick={()=>changeLanguage('pl')} className='btn-lng'>
                        <img src="/pl.png" alt="Polish" />
                    </button>
                    <button onClick={()=>changeLanguage('bg')} className='btn-lng'>
                        <img src="/bg.png" alt="Bulgarian" />
                    </button>
                    <button onClick={()=>changeLanguage('jp')} className='btn-lng'>
                        <img src="/jp.png" alt="Japanese" />
                    </button>
                    <button onClick={()=>changeLanguage('uk')} className='btn-lng'>
                        <img src="/ua.png" alt="Ukrainian" />
                    </button>




                 </li>
                    
            </ul>
            </div>


            <div className='hambUp'>
            <button className="hamburger"
                onClick={handleNavCollapse}
        >
        
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
        >
            <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
            />
        </svg>
        </button>
            </div>


        </div>
    </div> */}

    {/* <header>
        <nav className=''>
            <div className=''>
                <a href="http://localhost" className=''> 
                    <img src='logo.png' className='' alt='Logo'/>
                    <span className=''>Ua.Media</span>
                </a>
                <div>

                </div>
                <div>

                </div>
            </div>
        </nav>
    </header> */}


<nav className="flex items-center justify-between flex-wrap bg-sky-500">
  <div className="flex items-center flex-shrink-0 text-white mr-6">
    <svg className="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/></svg>
    <span className="font-semibold text-xl tracking-tight">UA.Media</span>
  </div>
  <div className="block lg:hidden">
    <button onClick={handleNavCollapse} className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
      <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
    </button>
  </div>
  <div className={`w-full pl-12 flex-grow lg:flex lg:items-center lg:w-auto ${isNavExpanded ? "block" : "hidden"}`}>

  <SearchComponent />
    <div className="text-lg lg:flex-grow text-end font-semibold pr-10">
      <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4">
      {t('News Feed')}
      </a>
      <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4">
      {t('About')}
      </a>
      <a href="/support" className="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4">
      {t('Support')}
      </a>
      
    </div>
<div>

{/* 
<button id="dropdownDefaultButton" onClick={()=>handleDropDown()} data-dropdown-toggle="dropdown" className="sm:hidden mr-4 text-black hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Language <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
  </svg>
</button>

<div id="dropdown" className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ${isOpen ? "block" : "hidden"}`}>



    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
      <li>
      <button onClick={()=>changeLanguage('en')} className='btn-lng'>
                        <img src="/en.png" alt="English" />
                    </button>
      </li>
      <li>
      <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
      </li>
      <li>
      <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
      </li>
      <li>
      <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
      </li>
    </ul>
</div>   */}

</div>

   
    {/* <div>
      <a href="#" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">Download</a>
    </div> */}
<button onClick={()=>changeLanguage('en')} className='btn-lng'>
                        <img src="/en.png" alt="English" />
                    </button>
                    <button onClick={()=>changeLanguage('es')} className='btn-lng'>
                        <img src="/spain.png" alt="Spanish" />
                    </button>
                    <button onClick={()=>changeLanguage('ge')} className='btn-lng'>
                        <img src="/ge.png" alt="German" />
                    </button>
                    <button onClick={()=>changeLanguage('fr')} className='btn-lng'>
                        <img src="/fr.png" alt="French" />
                    </button>
                    <button onClick={()=>changeLanguage('pl')} className='btn-lng'>
                        <img src="/pl.png" alt="Polish" />
                    </button>
                    <button onClick={()=>changeLanguage('bg')} className='btn-lng'>
                        <img src="/bg.png" alt="Bulgarian" />
                    </button>
                    <button onClick={()=>changeLanguage('jp')} className='btn-lng'>
                        <img src="/jp.png" alt="Japanese" />
                    </button>
                    <button onClick={()=>changeLanguage('uk')} className='btn-lng'>
                        <img src="/ua.png" alt="Ukrainian" />
                    </button>

  </div>
</nav>

</div>






    
    

      
      </Suspense>
    );
  }