import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAsync, selectArticle } from './articleSlice';
import { useParams } from "react-router-dom";
import Navbar from '../../components/Navbar';
import styles from './Article.module.css';

import { useTranslation } from "react-i18next";

import defaultImage from '../../img.svg';

export function Article(){

    const { i18n } = useTranslation();

    let params = useParams();
    
    const article = useSelector(selectArticle);
    
    //title description language 
    if(i18n.language === 'uk'){
        document.querySelector('meta[property="og:description"]')['content'] = article?.header ? article.header : "Усе про новини";
        document.querySelector('meta[property="og:title"]')['content'] = article?.header ? article.header : "Усе про новини";
        document.querySelector('meta[property="og:image"]')['content'] = article?.image ? article.image : "";
        document.querySelector('meta[property="og:url"]')['content'] = article?.id ? "https://ua.media/read/"+article.id : "https://ua.media/"
        document.querySelector('meta[property="og:locale"]')['content'] = "uk_uk";

        document.title = article?.header ? article.header : "Усе про новини";

        document.querySelector('meta[name="description"]')['content'] = article?.header ? article.header : "Усе про новини";
        document.querySelector('meta[name="title"]')['content'] = article?.header ? article.header : "Усе про новини";
        document.querySelector('meta[name="language"]')['content'] = "ukrainian";
        
    }else{
        document.querySelector('meta[property="og:description"]')['content'] = article?.header ? article.header : "All about News";
        document.querySelector('meta[property="og:title"]')['content'] = article?.header ? article.header : "All about News";
        document.querySelector('meta[property="og:locale"]')['content'] = "en_US";
        document.querySelector('meta[property="og:image"]')['content'] = article?.image ? article.image : "";
        document.querySelector('meta[property="og:url"]')['content'] = article?.news_id ? "https://ua.media/read/" + article.news_id : "https://ua.media/";
      
        document.title = article?.header ? article.header : "All about News";
        
        document.querySelector('meta[name="description"]')['content'] = article?.header ? article.header : "All about News";
        document.querySelector('meta[name="title"]')['content'] = article?.header ? article.header : "All about News";
        document.querySelector('meta[name="language"]')['content'] = i18n.language;

   }

    document.title = article?.header ? article.header : "All about News";
    document.language = article?.header ? article.header : "All about News";

    document.querySelector('meta[property="og:description"]')['content'] = article?.header ? article.header : "All about News";
    document.querySelector('meta[name="keywords"]')['content'] = article?.keywords ? article.keywords : "All about News";

    //title image url description locale( en_US uk_UA)
    const dispatch = useDispatch();
    useLayoutEffect(()=>{
        dispatch(fetchAsync({"id":params.id,"language":i18n.language}))
    },[dispatch,params.id,i18n.language])
        

    return(
        <div>
        <Navbar />
        <div className="container bgtop">
        </div>
        <article>
          <div className={styles.imgdiv}>
            <img className={styles.img} src={article?.image ? article.image : defaultImage} alt={article.header}/>
          </div>
            <div className={styles.header}>
            {article.header}
            </div>
            
            <div dangerouslySetInnerHTML={{ __html: article.full }}>
            
         </div>
         
         {/* <div className={styles.readmore}><a href={article.link} target="_blank" rel="noreferrer">{t("Read From Source")}</a></div> */}
         <div className={styles.date}>{new Date(article.updated_at).toLocaleString('uk-ua') }</div>
          </article>
      </div>
    );
}