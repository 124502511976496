import React from 'react';
import './App.css';
import { News } from './features/news/News';
import Navbar from './components/Navbar';
import SidebarComponent from './components/SidebarComponent';


function App() {

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className='bgtop'></div>
        <div className='maincontext'>
          <div className='context_middle'>
          <News />
          </div>
          <div className='context_right_sidebar'> 
              <SidebarComponent />
          </div>
        </div>  
      </div>
    </div>
  );
}

export default App;
