import { configureStore } from '@reduxjs/toolkit';
import newsReducer from '../features/news/newsSlice'
import articleReducer from '../features/article/articleSlice';

export const store = configureStore({
  reducer: {
    news: newsReducer,
    article: articleReducer
  },
});
