import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationUK from './locales/uk/translation.json';
import translationGE from './locales/ge/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationPL from './locales/pl/translation.json';
import translationJP from './locales/jp/translation.json';
import translationPT from './locales/pt/translation.json';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const resources = {
    en: {
      translation: translationEN
    },
    uk: {
      translation: translationUK
    },
    ge: {
      translation: translationGE
    },
    fr: {
      translation: translationFR
    },
    pl: {
      translation: translationPL
    },
    es: {
      translation: translationES
    },
    jp: {
      translation: translationJP
    },
    pt: {
      translation: translationPT
    }
  };

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    detection:{
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

 

export default i18n;