import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAsync, selectNews } from './newsSlice';
import { Link } from "react-router-dom";
import styles from './News.module.css';

import { useTranslation } from "react-i18next";
import defaultImage from '../../img.svg';

export function News(){
    

    const [amount, setAmount] = useState(20);

    //const [scrallnews, setScrallNews] = useState(()=>[]);//used in virtuoso infinite scralling
    
    const { t, i18n } = useTranslation();

    //console.log(i18n.language);

    const news = useSelector(selectNews);
    const dispatch = useDispatch();
    useEffect(()=>{
        //loadMore();//infinite scralling virtuoso

        dispatch(fetchAsync({"amount":amount,"language":i18n.language}))

        document.querySelector('meta[property="og:description"]')['content'] = i18n.language === "uk" ? "Новини, Україна, Мир: найважливіше в Україні в режимі реального часу" : "News, Ukraine, Peace: most important news from Ukraine and the world";
        document.querySelector('meta[property="og:title"]')['content'] = i18n.language === "uk" ? "Новини, Україна, Мир: найважливіше в Україні в режимі реального часу" : "News, Ukraine, Peace: most important news from Ukraine and the world";
        document.title = i18n.language === "uk" ? "Новини, Україна, Мир: найважливіше в Україні в режимі реального часу" : "News, Ukraine, Peace: most important news from Ukraine and the world";
    

    },[dispatch,amount,i18n.language])
        
    //virtuoso
    
    return(
    <>
        {
        news?.map((item,key) =>
        <div key={key} className="flex">
            <div className={styles.list_image}><img src={item?.image ? item.image : defaultImage} className={styles.img} alt={item.header}/>
            <div className={styles.list_article}>
                <div className={styles.header}>{item.header}</div>
                <div dangerouslySetInnerHTML={{ __html: item.full_list }}></div>
                    
            <div className={styles.date}><p className="text-blue-600/25">{new Date(item.created_at).toLocaleString('uk-ua') }</p></div>       
            <div className={styles.readmore}>
            <Link to={`/read/${item.news_id}`}
            key={item.id}
            >{t('Read more')}
            </Link>
            </div>
            
                <div>
            </div>
            

                </div>
            </div>
            
            
        </div>
    )}
    <div className={styles.loadMore}>
        
        <button className={styles.readMore} onClick={()=>{setAmount(amount+20);dispatch(fetchAsync({"amount":amount,"language":i18n.language}))}}>{t('Load more news')}</button>
        
    </div>
       </>
    )
    
}