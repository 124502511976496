import React from "react";
import {createBrowserRouter} from "react-router-dom";

import App from '../App';
import { Article } from '../features/article/Article';
import { AddNews } from "../components/AddNews";
import About from "../screens/about";
import Support from "../screens/support";
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/read/:id",
        element: <Article />
    },
    {
        path: "/add-news",
        element: <AddNews />
    },
    {
        path: "/about",
        element: <About />
    },
    {
        path: "/support",
        element: <Support />
    },
    
])

export default router;
